import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">

        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>


            <Route path={`${match.url}company/companies`} component={asyncComponent(() => import('./Company'))}/>

            <Route path={`${match.url}company/manage-company/:slug`}
                   component={asyncComponent(() => import('./ManageCompany'))}/>

            <Route path={`${match.url}company/account-chart/:slug`}
                   component={asyncComponent(() => import('./AccountChart'))}/>

            <Route path={`${match.url}company/payroll-items/:slug`}
                   component={asyncComponent(() => import('./PayrollItems'))}/>

            <Route path={`${match.url}company/users/:slug`}
                   component={asyncComponent(() => import('./Users'))}/>

            <Route path={`${match.url}company/accounts`} component={asyncComponent(() => import('./Accounts'))}/>
            <Route path={`${match.url}company/items`} component={asyncComponent(() => import('./Items'))}/>
            <Route path={`${match.url}company/account-summaries`}
                   component={asyncComponent(() => import('./AccountSummary'))}/>

            <Route path={`${match.url}organizations/departments`}
                   component={asyncComponent(() => import('./Departments'))}/>

            <Route path={`${match.url}organizations/education`}
                   component={asyncComponent(() => import('./Education'))}/>

            <Route path={`${match.url}organizations/educational-records`}
                   component={asyncComponent(() => import('./EducationalRecords'))}/>

            <Route path={`${match.url}organizations/employees`}
                   component={asyncComponent(() => import('./Employees'))}/>

            <Route path={`${match.url}organizations/file-uploads`}
                   component={asyncComponent(() => import('./FileUploads'))}/>

            <Route path={`${match.url}organizations/leave-applications`}
                   component={asyncComponent(() => import('./LeaveApplications'))}/>

            <Route path={`${match.url}organizations/professional-qualifications`}
                   component={asyncComponent(() => import('./ProfessionalQualification'))}/>

            <Route path={`${match.url}organizations/work-experiences`}
                   component={asyncComponent(() => import('./WorkExperience'))}/>


            <Route path={`${match.url}organizations/beneficiaries`}
                   component={asyncComponent(() => import('./Benefits'))}/>
            <Route path={`${match.url}organizations/dependents`}
                   component={asyncComponent(() => import('./Dependents'))}/>
            <Route path={`${match.url}organizations/guarantors`}
                   component={asyncComponent(() => import('./Guarantors'))}/>
            <Route path={`${match.url}organizations/next-kins`}
                   component={asyncComponent(() => import('./NextKins'))}/>


            <Route path={`${match.url}employees/employee-permissions`}
                   component={asyncComponent(() => import('./EmployeePermission'))}/>
            <Route path={`${match.url}security/authorizations/:slug`}
                   component={asyncComponent(() => import('./Authorizations'))}/>
            <Route path={`${match.url}security/ip-restrictions`}
                   component={asyncComponent(() => import('./IpRestrictions'))}/>


            <Route path={`${match.url}security/roles`}
                   component={asyncComponent(() => import('./UserRoles'))}/>
            <Route path={`${match.url}security/logs`}
                   component={asyncComponent(() => import('./ActionLogs'))}/>

            <Route path={`${match.url}settings/system-settings`}
                   component={asyncComponent(() => import('./SystemSettings'))}/>
            <Route path={`${match.url}application/documentation`}
                   component={asyncComponent(() => import('./Documentation'))}/>


        </Switch>
    </div>
);

export default App;
