import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled} from "@ant-design/icons";
import {FaCity, FaUsers, FaCog, FaKey, FaBuilding} from "react-icons/fa";
import Helpers from "../../util/Helpers";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    const {authorizations} = useSelector(({auth}) => auth);

    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <div>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className=" bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>
                {Helpers.authorizeMe(authorizations[7], 'view', 23) && (

                    <MenuItemGroup key="dashboard" className="mb-5">

                        <Menu.Item key="home">
                            <Link to="/home" className="flex">
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-4 py-1">Home</span>
                            </Link>
                        </Menu.Item>

                    </MenuItemGroup>
                )}

                <MenuItemGroup key="accounting" className="gx-menu-group "
                               title={<div className="px-3 text-left text-white">ADMINISTRATION</div>}>

                    {(Helpers.authorizeMe(authorizations[7], 'view', 24)
                        ||
                        Helpers.authorizeMe(authorizations[7], 'view', 25)
                        ||
                        Helpers.authorizeMe(authorizations[7], 'view', 26)
                    )
                    && (
                        <SubMenu key="company"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaCity/>
                                     </div>
                                     <div className="px-8 -my-3">Company</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) &&

                            (
                                <Menu.Item key="company/companies">
                                    <Link to="/company/companies">

                                        <span className="pl-7 text-gray-200 text-sm">Business</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {Helpers.authorizeMe(authorizations[7], 'view', 25) && (
                                <Menu.Item key="company/accounts">
                                    <Link to="/company/accounts">
                                        <span className="pl-7 text-gray-200 text-sm">Accounts</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 26) && (
                                <Menu.Item key="company/items">
                                    <Link to="/company/items">
                                        <span className="pl-7 text-gray-200 text-sm">Items</span>
                                    </Link>
                                </Menu.Item>)}

                        </SubMenu>)}
                    {Helpers.authorizeMe(authorizations[7], 'view', 27) && (
                        <SubMenu key="employees"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaUsers/>
                                     </div>
                                     <div className="px-8 -my-3">Employees</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[7], 'view', 27) && (
                                <Menu.Item key="employees/employee-permissions">
                                    <Link to="/employees/employee-permissions">
                                        <span className="pl-7 text-gray-200 text-sm">Employee Permissions</span>
                                    </Link>
                                </Menu.Item>)}


                        </SubMenu>)}
                    {Helpers.authorizeMe(authorizations[7], 'view', 27) && (
                        <SubMenu key="employees"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaUsers/>
                                     </div>
                                     <div className="px-8 -my-3">Application</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[7], 'view', 27) && (
                                <Menu.Item key="application/documentation">
                                    <Link to="/application/documentation">
                                        <span className="pl-7 text-gray-200 text-sm">Documentations</span>
                                    </Link>
                                </Menu.Item>)}


                        </SubMenu>)}

                    {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                        <SubMenu key="organizations"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaBuilding/>
                                     </div>
                                     <div className="px-8 -my-3">Organizations</div>
                                 </div>}>
                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/cases">
                                    <Link to="/organizations/cases">
                                        <span className="pl-7 text-gray-200 text-sm">Cases</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/beneficiaries">
                                    <Link to="/organizations/beneficiaries">
                                        <span className="pl-7 text-gray-200 text-sm">Beneficiaries</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/departments">
                                    <Link to="/organizations/departments">
                                        <span className="pl-7 text-gray-200 text-sm">Departments</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/dependents">
                                    <Link to="/organizations/dependents">
                                        <span className="pl-7 text-gray-200 text-sm">Dependents</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/employees">
                                    <Link to="/organizations/employees">
                                        <span className="pl-7 text-gray-200 text-sm">Employees</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/educational-records">
                                    <Link to="/organizations/educational-records">
                                        <span className="pl-7 text-gray-200 text-sm">Educational Record</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/education">
                                    <Link to="/organizations/education">
                                        <span className="pl-7 text-gray-200 text-sm">Education (Raw)</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/employee-queries">
                                    <Link to="/organizations/employee-queries">
                                        <span className="pl-7 text-gray-200 text-sm">Employee Queries</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/file-uploads">
                                    <Link to="/organizations/file-uploads">
                                        <span className="pl-7 text-gray-200 text-sm">File Uploads</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/guarantors">
                                    <Link to="/organizations/guarantors">
                                        <span className="pl-7 text-gray-200 text-sm">Guarantors</span>
                                    </Link>
                                </Menu.Item>)}


                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/leave-applications">
                                    <Link to="/organizations/leave-applications">
                                        <span className="pl-7 text-gray-200 text-sm">Leave Applications</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/next-kins">
                                    <Link to="/organizations/next-kins">
                                        <span className="pl-7 text-gray-200 text-sm">Next of Kins</span>
                                    </Link>
                                </Menu.Item>)}
                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/professional-qualifications">
                                    <Link to="/organizations/professional-qualifications">
                                        <span className="pl-7 text-gray-200 text-sm">Professional Qualifications</span>
                                    </Link>
                                </Menu.Item>)}

                            {Helpers.authorizeMe(authorizations[7], 'view', 24) && (
                                <Menu.Item key="organizations/work-experiences">
                                    <Link to="/organizations/work-experiences">
                                        <span className="pl-7 text-gray-200 text-sm">Work Experience</span>
                                    </Link>
                                </Menu.Item>)}


                        </SubMenu>)}
                    {(
                        Helpers.authorizeMe(authorizations[7], 'view', 28)
                        ||
                        Helpers.authorizeMe(authorizations[7], 'view', 33)

                    ) && (
                        <SubMenu key="security"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaKey/>
                                     </div>
                                     <div className="px-8 -my-3">Security</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[7], 'view', 33) && (
                                <Menu.Item key="security/logs">
                                    <Link to="/security/logs">

                                        <span className="pl-7 text-gray-200 text-sm">Action Log</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {Helpers.authorizeMe(authorizations[7], 'view', 33) && (
                                <Menu.Item key="security/ip-restrictions">
                                    <Link to="/security/ip-restrictions">

                                        <span className="pl-7 text-gray-200 text-sm">IP Restrictions</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {Helpers.authorizeMe(authorizations[7], 'view', 28) && (
                                <Menu.Item key="security/roles">
                                    <Link to="/security/roles">

                                        <span className="pl-7 text-gray-200 text-sm">Roles</span>
                                    </Link>
                                </Menu.Item>
                            )}

                        </SubMenu>)}
                    {Helpers.authorizeMe(authorizations[7], 'view', 29) && (
                        <SubMenu key="settings"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <Link to="/settings"><FaCog/>
                                         </Link>

                                     </div>
                                     <div className="px-8 -my-3">Settings</div>
                                 </div>}>
                            {Helpers.authorizeMe(authorizations[7], 'view', 29) && (
                                <Menu.Item key="settings/system-settings">
                                    <Link to="/settings/system-settings">

                                        <span className="pl-7 text-gray-200 text-sm">System Settings</span>
                                    </Link>
                                </Menu.Item>)}

                        </SubMenu>)}

                </MenuItemGroup>

            </Menu>
        </div>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

